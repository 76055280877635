

.DemoReel{
    border: none;
    width: 80%;
    height: 50vw;
}

.portfolioDemoReel{
    margin-top: 30px;
    height: 100vh;
}