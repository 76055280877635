 h1 {
    color: #48B749;
  }

p{
    color:#4e5058
}

.App-link{
    color: #FFFFFF;
    padding: 5px 20px 5px 20px;
    border: none;
    border-radius: 20px;
    margin-top: 30px;
    text-decoration: none;
    background-color: #48B749;
}

.App-link:hover{
    /* color: #e5e6e5; */
    background-color: rgb(58, 150, 59);
    /* border: #e5e6e5 2px solid; */
}

.App-link:active{
    /* color: #e5e6e5; */
    background-color: rgb(41, 128, 42);
    /* border: #e5e6e5 2px solid; */
}